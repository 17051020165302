import * as React from "react";

import Layout from "@layout/index";
import Header from "@components/head";
import { Box, Text, VStack } from "@chakra-ui/react";
import { loadScript } from "@/utils";

const { useEffect } = React;

export const Head = () => {
  return (
    <Header
      title="Airgram Changelog | Level Up Note-taking Experience for GMeet and Zoom Meetings"
      description="Changelog of Airgram. Stay tuned for our product updates, roadmap, and fixes. Learn what&#x27;s new in Airgram."
    />
  )
}

const UpvotyBox = () => {
  useEffect(() => {
    loadScript('https://airgram.upvoty.com/javascript/upvoty.embed.js', 'upvoty-script').then(() => {
      // @ts-ignore
      upvoty?.init("render", {
        startPage: "changelog",
        baseUrl: "airgram.upvoty.com",
      });
    })
    return () => {
      // @ts-ignore
      upvoty?.destroy();
    };
  }, []);
  return <Box w="full" minH="100vh" data-upvoty></Box>;
};

const ChangelogPage: React.FC<any> = () => {
  return (
    <Layout>
      <VStack>
        <Text
          fontSize="24px"
          fontWeight={600}
          textAlign="center"
          as="h1"
          color="#220247"
        >
          What's New in Airgram
        </Text>
        <UpvotyBox />
      </VStack>
    </Layout>
  );
};

export default ChangelogPage;
